import React from 'react'
import Layout from "../layout";
import {Button, Col, Container, Row, Card} from "react-bootstrap";
import {Link} from "gatsby";
import {IoArrowBack, IoArrowForward} from "react-icons/io5";
import Seo from "../seo";
import ShareWidget from "../share-widget";
import logo from '../../images/icon.png';
import Img from "gatsby-image";

const readingTime = (wordCount) => Math.ceil(wordCount / 200);

const PostTemplate = ({ pageContext }) => {
	const {
		data: {
			preview_image: { url: previewImageUrl, original_url: previewOriginalUrl, thumbnails: { square: { url: previewSquare }, portrait: { url: previewPortrait } } },
			title: { html: title, text: seoTitle }, description, content,
			author: { document: { data: { name, avatar: { url: avatar } } } },
			related
		},
		first_publication_date: publicationDate, first_publication_datetime: publicationDateTime,
		last_publication_datetime: lastModifiedDateTime,
		url
	} = pageContext;

	return (
		<Layout>
			<Seo
				title={seoTitle}
				description={description}
				lang="en"
				image={previewOriginalUrl}
				canonical={url}
				type="article"
				meta={[
					{ property: 'article:published_time', content: publicationDateTime },
					{ property: 'article:section', content: 'Digital marketing' },
					{ property: 'article:author', content: name },
					{ property: 'article:publisher', content: 'https://facebook.com/arbitery' },
				]}
				structuredData={{
					"@context": "https://schema.org",
					"@type": "Article",
					"headline": seoTitle,
					"image": [
						previewSquare,
						previewPortrait,
						previewOriginalUrl
					],
					"datePublished": publicationDateTime,
					"dateModified": lastModifiedDateTime,
					"author": {
						"@type": "Person",
						"name": name
					},
					"publisher": {
						"@type": "Organization",
						"name": "Arbitery",
						"logo": {
							"@type": "ImageObject",
							"url": logo
						}
					}
				}}
			/>
			<Container className="py-3">
				<Button as={Link} to='/blog/' variant="link" className="d-flex align-items-center text-uppercase p-0">
					<IoArrowBack />&nbsp;All posts
				</Button>
				<article>
					<div className="pt-4" dangerouslySetInnerHTML={{ __html: title }} />
					<p className="text-muted">{description}</p>
					<Row className="align-items-center">
						<Col xs="12" md="6" className="d-flex justify-content-start mb-3 align-items-center">
							<Img className="mr-3 rounded-circle" width="48" height="48" fixed={avatar} alt={name} />
							<div className="d-flex flex-column">
								<b>{name}</b>
								<div>
									{readingTime(content.text.split(' ').length)} min read · <time dateTime={publicationDateTime}>{publicationDate}</time>
								</div>
							</div>
						</Col>
						<Col xs="12" md="6" className="mb-3">
							<div className="text-left text-md-right mr-n1">
								<ShareWidget />
							</div>
						</Col>
					</Row>
					<Row className="pt-3">
						<Col xs="12" md="8" className="mb-3">
							<Img fluid={previewImageUrl} alt={title} className="w-100 mb-3"/>
							<div dangerouslySetInnerHTML={{ __html: content.html }} />
						</Col>
						<Col xs="12" md="4" className="mb-3">
							<div className="h4">Related posts</div>
							<Row>
								{related && related.map(node => (
									<Col xs="12" className="mb-3">
										<Card className="shadow-sm">
											<Card.Img as={Img} variant="top" fluid={node.posts.document.data.preview_image.thumbnails.small.url} alt={node.posts.document.data.title.text} />
											<Card.Body>
												<Card.Title>
													{node.posts.document.data.title.text}
												</Card.Title>
												<Card.Text className="text-secondary">
													{node.posts.document.data.description}
												</Card.Text>
												<Card.Link as={Link} to={node.posts.document.url} title={node.posts.document.data.title.text}>Read more&nbsp;<IoArrowForward /></Card.Link>
											</Card.Body>
										</Card>
									</Col>
								))}
							</Row>
						</Col>
					</Row>
				</article>
			</Container>
		</Layout>
	)
}

export default PostTemplate