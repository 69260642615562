import React from 'react';
import {
	EmailShareButton,
	WhatsappShareButton,
	FacebookShareButton,
	PinterestShareButton,
	RedditShareButton,
	TelegramShareButton,
	TwitterShareButton,
	EmailIcon,
	WhatsappIcon,
	FacebookIcon,
	PinterestIcon,
	RedditIcon,
	TelegramIcon,
	TwitterIcon,
} from "react-share";

const buttons = [
	{ Button: FacebookShareButton, Icon: FacebookIcon },
	{ Button: TwitterShareButton, Icon: TwitterIcon },
	{ Button: PinterestShareButton, Icon: PinterestIcon },
	{ Button: WhatsappShareButton, Icon: WhatsappIcon },
	{ Button: TelegramShareButton, Icon: TelegramIcon },
	{ Button: RedditShareButton, Icon: RedditIcon },
	{ Button: EmailShareButton, Icon: EmailIcon }
]
const ShareWidget = () => {
	const url = typeof window !== 'undefined' ? window.location.href : '';
	return buttons.map(({ Button, Icon }) => (
		<Button className="mr-1" url={url}>
			<Icon size={32} round />
		</Button>
	))
}

export default ShareWidget;